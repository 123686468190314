<template>
  <div class="page-wrapper">
    <h1 class="login-page-title">Réinitialisation du mot de passe</h1>

    <!-- Loader -->
    <div v-show="user === undefined" data-test="loader">Authenticating...</div>

    <!-- Offline instruction -->
    <div v-show="!networkOnLine" data-test="offline-instruction">
      Please check your connection, login feature is not available offline.
    </div>

    <p v-if="loginError">{{ loginError }}</p>

    <!-- Auth UI -->
    <form action="">
      <div>
        <label for="email">Courriel</label>
        <input id="email" v-model="email" type="email" required />
      </div>

      <div>
        <label for="emailConfirm">Confirmation du courriel</label>
        <input id="emailConfirm" v-model="emailConfirm" type="email" required />
      </div>

      <div>
        <button type="submit" class="bt" :class="{ 'is-loading': isLoading }" @click.prevent="resetPassword()">
          <div class="bt-text">
            <p>Réinitialiser le mot de passe</p>
          </div>
          <p class="loader">
            &nbsp;
            <svg class="main-loader" width="38" height="38" xmlns="http://www.w3.org/2000/svg" stroke="#EFF1F2">
              <g transform="translate(1 1)" stroke-width="2" fill="none" fill-rule="evenodd">
                <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
                <path d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="1s"
                    repeatCount="indefinite"
                  />
                </path>
              </g>
            </svg>
            &nbsp;
          </p>
        </button>
      </div>
    </form>

    <hr />

    <router-link :to="{ name: 'connexion' }">Retour à la connexion</router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { isNil } from 'lodash'
import { auth } from '@/firebase/init'

export default {
  data: () => ({ isLoading: true, loginError: null, email: '', emailConfirm: '' }),
  head() {
    return {
      title: {
        inner: 'Login'
      },
      meta: [
        {
          name: 'description',
          content: `Sign in or sign up to ${this.appTitle}`,
          id: 'desc'
        }
      ]
    }
  },
  computed: {
    ...mapState('authentication', ['user']),
    ...mapState('app', ['networkOnLine', 'appTitle'])
  },
  watch: {
    user: {
      handler(user) {
        if (!isNil(user)) {
          this.isLoading = true

          const redirectUrl = isNil(this.$route.query.redirectUrl) ? '/tableau-de-bord' : this.$route.query.redirectUrl
          this.$router.push(redirectUrl)
        } else {
          this.isLoading = false
        }
      },
      immediate: true
    }
  },
  methods: {
    async resetPassword() {
      if (this.email !== this.emailConfirm) {
        console.log('Err: ', 'Email confirmation')
        return false
      }

      this.isLoading = true

      try {
        await auth.sendPasswordResetEmail(this.email)
        this.$router.push({ name: 'connexion' })
      } catch (error) {
        this.loginError = error
        this.isLoading = false
      }

      return true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
